*{
  padding: 0;
  margin: 0;
  font-family: 'Outfit', sans-serif;
  color: white;
}

html{
  background: linear-gradient(45deg, black, #181817);
  background-size: 100% 300%;
}

.App{
  display: grid;
  justify-items: center;
  text-align: center;
  gap: 10px;
  padding-bottom: 20px;
}

button{
  width: fit-content;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background: #ffde73;
  color: #31302d;
  font-size: 1em;
}

button:hover{
  cursor: pointer;
  background: #1a1918;
  color: #ffde73;
}

#Header{
  display: grid;
  justify-items: center;
  gap: 10px;
}

#Cards{
  display: grid;
  gap: 10px;
}

#TopCard{
  display: grid;
  justify-items: center;
  gap: 10px;
  /*background: linear-gradient(45deg, #FFFFFF50, #00000050,#FFFFFF50, #00000050,#FFFFFF50);*/
  background:  #383631;
  border-radius: 10px;
  border: 2px solid #ffde7360;
  padding: 20px;
}

#TopCard button{
  width: 100%;
}

#Logo{
  width: 150px;
  margin: 20px 0px 0px 0px;
}

#NFT{
  width: 250px;
  border-radius: 20px;
}

#Socials{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 10px;
  align-items: center;
  width: 100%;
}

a{
  text-decoration: underline;
  color: white;
}

canvas {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width: 1000px) {
  canvas{
    visibility: hidden;
    display: none;
  }
}